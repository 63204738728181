import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import client from '../Contentful_API/api';
import Knowledge from './Knowledge';

const Loader = () => (
    <div class="divLoader">
      <svg class="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
        <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
      </svg>
    </div>
);

class RecentKnowledgeList extends Component {

    state = {
        knowlegeCenter: [],
        searchString: '',
        loading: true
    }
    constructor() {
        super()
        this.getKnowledgeCenter()
    }
    getKnowledgeCenter = () => {
        client.getEntries({
            content_type: 'knowlegeCenter',
            limit: 8,
            query: this.state.searchString
        })
        .then((response) => {
            this.setState({knowlegeCenter: response.items, loading: false})
            console.log(this.state.knowlegeCenter)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getKnowledgeCenter()
    }
    render() {
        return (
            <div>
                {this.state.loading ? <Loader /> : null}
                { this.state.knowlegeCenter ? (
                    <div>
                        <Grid container spacing={2} style={{padding: 24}}>
                            { this.state.knowlegeCenter.map(currentKnowledge => (
                               <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Knowledge knowlegeCenter={currentKnowledge} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "No Knowledge found" }
            </div>
        )
    }
}

export default RecentKnowledgeList;