import React from 'react';
import Home from './components/Home/Home'
import Profile from './components/Profile/Profile'
import Navbar from './components/Navbar/Navbar'
import Research from './components/Research/Research'
import Programs from './components/Programs/Programs'
import Footer from './components/Footer/Footer'
import history from './History';
import {Router,Route } from "react-router-dom";
import SinglePublication from './components/Publications/SinglePublication'
import SingleVacancy from './components/Jobs/SingleJob'
import SingleEvent from './components/Events/SingleEvent'
import EventList from './components/Events/EventList'
import PublicationList from './components/Publications/PublicationList'
import TenderList from './components/Tenders/TenderList'
import TenderArciveList from './components/tenders_archive/TenderArchiveList';
import KnowledgeList from './components/KnowledgeCenter/KnowledgeList'
import SingleKnowledge from "./components/KnowledgeCenter/SingleKnowledge"
import DirectorateList  from './components/Directorate/DirectorateList'
import SingleDirectorate from './components/Directorate/SingleDirectorate'
import TenderDetails from "./components/Tenders/SingleTender"
import SingleMenu from './components/Navbar/MenuDetails'
import RevenueExpenditureList from "./components/InfoGraphic/RevenueExpenditureList"
import FormerMinisterLists from "./components/FormerMinisters/FormerMinisterLists"
import SearchList from "./components/Search/Search"
import SingleMinisterDetails from "./components/FormerMinisters/SingleMinisterDetails"
import ProcurementPage from './components/Pages/ProcurementPage';
import BudgetSpendingPage from './components/Pages/BudgetSpending';
import AidPage from './components/Pages/Aid';
import EconomyPage from './components/Pages/Economy';
import TreasuryDocument from './components/Documents/TreasuryDocument';
import PosterList from './components/PosterGraphic/PosterCollections';
import SinglePoster from './components/PosterGraphic/SinglePoster';
import FeedBack from './components/FeedBack/FeedBack';
import CustomRevenueListItem from './components/CustomRevenueCollection/CustomRevenueList';
import CustomRevenuDetails from './components/CustomRevenueCollection/CustomRevenuDetails';

function App(){
  return (
    <Router history={history}>
      <Navbar/>
          <div>
            <Route exact path="/" component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/searchpage" component={SearchList} />  
            <Route path="/research" component={Research} />    
            <Route path="/programs" component={Programs} /> 
            <Route path="/jobs/:id" component={SingleVacancy} />  
            <Route path="/tenders" component={TenderList} />   
            <Route path="/tenders_archive" component={TenderArciveList} />   
            <Route path="/directorates" component={DirectorateList} />
            <Route path="/publicationdetails/:slug" component={SinglePublication} />
            <Route path="/directoratedetails/:slug" component={SingleDirectorate} />
            <Route path="/formerministerdetails/:slug" component={SingleMinisterDetails} />
            <Route path="/eventdetails/:slug" component={SingleEvent} />
            <Route path="/tenderdetails/:slug" component={TenderDetails} />
            <Route path="/pagedetails/:slug" component={SingleMenu} />
            <Route path="/pressrelease/" component={EventList} />
            <Route path="/publications" component={PublicationList} />
            <Route path="/formerministers" component={FormerMinisterLists} />
            <Route path="/allknowledge" component={KnowledgeList} />
            <Route path="/procurementpage" component={ProcurementPage} />
            <Route path="/budgetspendingpage" component={BudgetSpendingPage} />
            <Route path="/aidpage" component={AidPage} />
            <Route path="/economypage" component={EconomyPage} />
            <Route path="/alltreasurydocuments" component={TreasuryDocument} />
            <Route path="/knowledgedetails/:slug" component={SingleKnowledge} />
            <Route path="/budgetandrevenueallyears" component={RevenueExpenditureList} />
            <Route path="/allpostergraphics" component={PosterList} />
            <Route path="/posterdetails/:slug" component={SinglePoster} />
            <Route path="/customrevenuedetails/:slug" component={CustomRevenuDetails} />
            <Route path="/allcustomrevenuecollections" component={CustomRevenueListItem} />
            <FeedBack/>
          </div>

      <Footer/>

    </Router>
  );
}

export default App;
