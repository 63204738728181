import React, { Component } from 'react'
import axios from 'axios'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { SimpleShareButtons } from "react-simple-share";
import "./styles.css";
import JobIcon from "../StaticImages/job.png"
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button';
import Loader from '../Loader/Loader';

const SocialShare = () => (
  <SimpleShareButtons
    whitelist={["Facebook", "Twitter", "LinkedIn", "Google+"]}
    size="20px"
  />
);

const muiBaseTheme = createMuiTheme();
const theme = {
overrides: {
  MuiCard: {
    root: {
      "&.MuiEngagementCard--01": {
        transition: "0.3s",
        maxWidth: 300,
        margin: "auto",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
          boxShadow: "0 30px 70px -12.125px rgba(0,0,0,0.5)"
        },
        "& .MuiCardMedia-root": {
          paddingTop: "56.25%"
        },
        "& .MuiCardContent-root": {
          textAlign: "left",
          padding: muiBaseTheme.spacing.unit * 3
        },
        "& .MuiDivider-root": {
          margin: `${muiBaseTheme.spacing.unit * 3}px 0`
        },
        "& .MuiTypography--heading": {
          fontWeight: "bold"
        },
        "& .MuiTypography--subheading": {
          lineHeight: 1.8
        },
        "& .MuiAvatar-root": {
          display: "inline-block",
          border: "2px solid white",
          "&:not(:first-of-type)": {
            marginLeft: -muiBaseTheme.spacing.unit
          }
        }
      }
    }
  }
}
};





class JobApplicationList extends Component {
    constructor(props) {
      super(props);
        this.state = {
          jobs: [],
          loading: true
        }
 
    }

    getJobs() {
      axios.get('https://mofhrsystem.herokuapp.com/api/v1/jobs')  
      .then(response => {
        this.setState({
          jobs: response.data,
          query: this.state.searchString,
          loading: false
        })
      })
      .catch(error => console.log(error))
    }
    
    componentDidMount() {
      this.getJobs()
    }  
    render() {
      return (
        <div>
        {this.state.loading ? <Loader /> : null}
        <Grid container spacing={2} style={{padding: 24}}>  
        { this.state.jobs.map(currentJobs => (
            <Grid item xs={12} sm={3} lg={3} xl={3}>
                <MuiThemeProvider theme={createMuiTheme(theme)}>
                  <div className="App">
                    <Card className={"MuiEngagementCard--01"}>
                      <CardMedia
                        image={JobIcon}
                      />
                      <CardContent>
                          <Typography
                            className="text"
                            variant={"h7"}
                            gutterBottom
                          >
                            {currentJobs.title}
                    
                        </Typography>
                        <Typography>
                        </Typography>
                            <Typography
                              className={"MuiTypography--subheading"}
                              variant={"caption"}
                            >
                            <Chip label={<SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{currentJobs.deadline}</SimpleDateTime>} />
                        </Typography>
                        <Divider light />
                        <Button variant="outlined" color="primary">
                          <Link to={`/jobs/${currentJobs.id}`} style={{ textDecoration: 'none' }}>
                            View Details
                          </Link>
                        </Button>
                        <SocialShare
                              url = {"https://mof.gov.tl/jobdetails/" + currentJobs.id }
                          />
                      </CardContent>
                    </Card>
                  </div>
                </MuiThemeProvider>
            </Grid>           
        ))}

            </Grid> 
        </div>
      );
    }
  
}

export default JobApplicationList
