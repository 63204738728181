import React, { Component } from 'react'
import PropTypes from 'prop-types';
import client from '../Contentful_API/api';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Button from '@material-ui/core/Button'
import { SimpleShareButtons } from "react-simple-share";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WorkIcon from '@material-ui/icons/Work';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import TreasuryDocument from '../Documents/TreasuryDocument';
import PlanningBudgetDocument from '../Documents/PlanningBudgetDocument';
import PetroleumFundDocument from '../Documents/PetroleumFund';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    
  },
  header: {
    //backgroundImage: `url(${LarinlauLospalos})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
}));


const SocialShare = () => (
    <SimpleShareButtons
      whitelist={["Facebook", "Twitter", "LinkedIn", "Google+"]}
      size="20px"
    />
  );

class SingleDirectorate extends Component {
	constructor(){
		super()
		this.state = {directorates: null}
	}

	componentDidMount() {
   	 client.getEntries({
        content_type: 'directorates', 'fields.slug': this.props.match.params.slug
      })
      .then(response => {
        return response.items[0].fields;
      })
      .then(fields => {
        this.setState({
        directorates: fields
        });
      })
      .catch(console.error);
  }

	render(props){
		const {classes} = this.props;
		let name;
		let contentdescription;
		let imageurl;
    let slug;
		if (this.state.directorates) {
        slug = this.state.directorates.slug;
      	name = this.state.directorates.name;
        contentdescription = this.state.directorates.contentdescription;
		    imageurl = this.state.directorates.logodirectorate.fields.file.url; 
		}	

    function DirectoratePublicationTab(props) {
      const classes = useStyles();
      const [value, setValue] = React.useState(0);
    
      const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    
      return (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab label="Details" icon={<MenuBookIcon />} {...a11yProps(0)}/>
              <Tab label="Documents & Reports" icon={<Badge badgeContent={"New"} color="secondary"><WorkIcon /></Badge>} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
              <div>
                <img  alt="complex" src= {imageurl} width="500" height="300"/>
              </div>
              <div className={classes.socialShare}>
                <h5>Share with</h5>
                <SocialShare
                    url = {"https://mof.gov.tl/directoratedetails/" + slug }
                />
                <br/>
                <Button variant="contained" color="primary" href="/profile">
                  Back
                </Button>
              </div>
            {documentToReactComponents(contentdescription)}
          </TabPanel>
          <TabPanel value={value} index={1}>
              <DirectorateDocument/>
          </TabPanel>
        </div>
      );
    }
    
    function DirectorateDocument(){
      if(name === 'Diresaun Jerál Tezouru') {
        return(
          <div className={classes.layout}>
            <br/>
              <TreasuryDocument/>
          </div>
        ) 
      } else if(name === 'Gabineti Inspesaun Jeral') {
          return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Diresaun Geral Gestaun Patrimoniu Estadu') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Gabineti Ministru Finansas') {
        return(
          <div className={classes.layout}>
            <br/>
          <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Gabineti Vise-Ministru Finansas') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Gabineti Politika no Gestaun Fundu Minarai') {
        return(
          <div className={classes.layout}>
            <br/>
              <PetroleumFundDocument/>
          </div>
        )
      } else if(name === 'Gabinete Sistema Integradu Informasaun Gestaun Finanseira') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Gabinete de Politíca de Reforma Capacitação Gestão Finanças Pública') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Gabineti Apoiu Juridiku') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Diresaun Geral Gestaun Mobilizasaun Rekursu Externa') {
        return(
          <div className={classes.layout}>
          <br/>
          <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Diresaun Geral Planeamentu E Orsamentu') {
        return(
          <div className={classes.layout}>
            <br/>
              <PlanningBudgetDocument/>
          </div>
        )
      } else if(name === 'Diresaun Geral Statistika') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Diresaun Geral Servisus Korporativus') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else if(name === 'Autoridade Aduaneira') {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        )
      } else {
        return(
          <div className={classes.layout}>
            <br/>
            <Grid container>
              
            </Grid>
          </div>
        ) 
      }
    }

    return(
      <div>
         <Grid container style={{padding: 24}}>
              <DirectoratePublicationTab/>
          </Grid>
      </div>
    )
   
	}
}

SingleDirectorate.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(SingleDirectorate);
