import React, {Component} from 'react';
import Container from '@material-ui/core/Container';

class Researh extends Component {
    
    render() {
        return(
            <Container>
                <p>Hello, hau mai hosi Komponente Researh!</p>
            </Container>
        ) 
    }
}

export default Researh;