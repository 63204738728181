import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import client from '../Contentful_API/api';
import {Link} from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { SimpleShareButtons } from "react-simple-share";
import Container from '@material-ui/core/Container';


const SocialShare = () => (
    <SimpleShareButtons
      whitelist={["Facebook", "Twitter", "LinkedIn", "Google+"]}
      size="20px"
    />
  );


const muiBaseTheme = createMuiTheme();
const theme = {
  overrides: {
    MuiCard: {
      root: {
        "&.MuiEngagementCard--01": {
          transition: "0.3s",
          maxWidth: 300,
          margin: "auto",
          boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          "&:hover": {
            boxShadow: "0 30px 70px -12.125px rgba(0,0,0,0.5)"
          },
          "& .MuiCardMedia-root": {
            paddingTop: "56.25%"
          },
          "& .MuiCardContent-root": {
            textAlign: "left",
            padding: muiBaseTheme.spacing.unit * 3
          },
          "& .MuiDivider-root": {
            margin: `${muiBaseTheme.spacing.unit * 3}px 0`
          },
          "& .MuiTypography--heading": {
            fontWeight: "bold"
          },
          "& .MuiTypography--subheading": {
            lineHeight: 1.8
          },
          "& .MuiAvatar-root": {
            display: "inline-block",
            border: "2px solid white",
            "&:not(:first-of-type)": {
              marginLeft: -muiBaseTheme.spacing.unit
            }
          }
        }
      }
    }
  }
};

function BudgetSpendingContent(props) {
  return (
      props.menus? (
        <MuiThemeProvider theme={createMuiTheme(theme)}>
        <div className="App">
        <Link onClick={() => {window.location.href="/pagedetails/"+ props.menus.fields.slug}} style={{ textDecoration: 'none' }}>
          <Card className={"MuiEngagementCard--01"}>
            <CardContent>
              <Typography
                 className="text"
                variant="small"
                gutterBottom
              >
                  {props.menus.fields.title}
              </Typography>
              <Divider light />
              <SocialShare
                    url = {"https://www.mof.gov.tl/pagedetails/" + props.menus.fields.slug }
                />
            </CardContent>
          </Card>
          </Link>
        </div>
      </MuiThemeProvider>
      ) : "No Publications" 
  )
}


class BudgetSpendingPage extends Component { 
    state = {
        menus: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getMenuList()
    }
    getMenuList = () => {
        client.getEntries({
            content_type: 'menus', 'fields.type': 'budegetandspending',
            order: 'fields.menuorder'
        })
        .then((response) => {
            this.setState({menus: response.items})
            console.log(this.state.menus)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })

    }
    render(props) {
        return (
            <div>
                <Container>
                <h4>Budget and Spending Page</h4>
                { this.state.menus ? (
                    <div>
                        <Grid container spacing={-30} style={{padding: -4}}>
                            { this.state.menus.map(menuList => (
                               <Grid item xs={12} sm={3} lg={3} xl={3}>
                                   <br/>
                                    <BudgetSpendingContent menus={menuList} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "Please wait while the date is being fetched" }
                </Container>
                <br/>
            </div>
        )
    }
}

export default BudgetSpendingPage;


