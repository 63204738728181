import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Logo from "./lgfinal.png"

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0),
  
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

export default function LogoCompany() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={Logo} width="220" height="80" alt="Logo MoF" />
    </div>
  );
}
