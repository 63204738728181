import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import client from '../Contentful_API/api';
import Tender from './Tender';

import Loader from '../Loader/Loader';

class RecentTender extends Component {

    state = {
        tenders: [],
        searchString: '',
        loading: true
    }
    constructor() {
        super()
        this.getTenders()
    }
    getTenders = () => {
        client.getEntries({
            content_type: 'tenders',
            query: this.state.searchString,
            limit: 8
        })
        .then((response) => {
            this.setState({tenders: response.items, loading: false})
            console.log(this.state.tenders)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getTenders()
    }
    render() {
        return (
            <div>
                {this.state.loading ? <Loader /> : null}
                { this.state.tenders ? (
                    <div>
                        <Grid container spacing={2} style={{padding: 24}}>
                            { this.state.tenders.map(recentTenders => (
                               <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Tender tenders={recentTenders} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "No Tender found" }
            </div>
        )
    }
}

export default RecentTender;