const styles = theme => ({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
        width: 1100,
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
        marginTop: '40px',
        marginBottom: '40px',
      },
    },
      image :{
        width: 300,
        height: 300,
      },
    
      imageLayout: {
        marginTop: theme.spacing.unit * 12,
        backgroundSize: 'cover',
        marginLeft: 'auto',
        marginRight: 'auto',  
      },
      eventBody: {
        marginTop: theme.spacing.unit * 8,
        backgroundSize: 'cover',
        marginLeft: 'auto',
        marginRight: 'auto',  
      },
      socialShare: {
        marginTop: theme.spacing.unit * 2,
        backgroundSize: 'cover',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
  
      img: {
        margin: 'auto',
        display: 'inline',
        maxWidth: '90%',
        maxHeight: '50%',
        paddingBottom: '0px',
      },
      card: {
        display: 'flex',
      },
      details: {
        minWidth: 275,
        flexDirection: 'column',
      },
      button: {
        margin: theme.spacing.unit,
        background: '#2E3B55',
      },
      content: {
        minWidth: 275,
        width: 200,
      },
      cover: {
        width: 180,
      },
      controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
      },
      playIcon: {
        height: 38,
        width: 38,
      },
    });
  
  export default styles;
