import React, { Component } from 'react'
import PropTypes from 'prop-types';
import client from '../Contentful_API/api';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles';
import Grid from '@material-ui/core/Grid';
import { SimpleShareButtons } from "react-simple-share";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import Button from '@material-ui/core/Button'
import TenderImageFile from "../StaticImages/tender.png"
import SimpleDateTime  from 'react-simple-timestamp-to-date';
import Alert from '@material-ui/lab/Alert';



const renderOptions = {
	renderNode: {
	  [INLINES.EMBEDDED_ENTRY]: (node, children) => {
		if (node.data.target.sys.contentType.sys.id === "5j4CdX67k92G8DhB43JYEi") {
		  return (
			<a href={`/publicationdetails/${node.data.target.fields.slug}`}>            
				{node.data.target.fields.name}
			</a>
		  );
		}
	  },
	  [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
		if (node.data.target.sys.contentType.sys.id === "5j4CdX67k92G8DhB43JYEi") {
		  return (
			<pre>
			  <code>Test COde</code>
			</pre>
		  );
		}
	  },
  
	  [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
		return (
		  <div>
			  	<br/>
			  	<Button variant="contained" color="inherit" > 
					<a className="buttonLink" href={`https://${node.data.target.fields.file.url}`} donwload  target="_blank"  rel="noreferrer"> Download Content [ENG, TL]</a> 
				</Button>
		  </div>
		  
		);
	  },
	},
  };

  
class TenderDetails extends Component {
	constructor(){
		super()
		this.state = {tenders: null}
	}

	
	componentDidMount() {
   	 client.getEntries({content_type: 'tenders', 'fields.slug': this.props.match.params.slug})
      .then(response => {
        return response.items[0].fields;
      })
      .then(fields => {
        this.setState({
			tenders: fields
        });
      })
      .catch(console.error);
  }

	render(props){
		
		const {classes} = this.props;

		let name;
		let slug;
		let code;
		let description;
		let deadline;
		let tenderSource;

		if (this.state.tenders) {
			name = this.state.tenders.name;
			slug = this.state.tenders.slug;
			code = this.state.tenders.code;
			description = this.state.tenders.description;
			deadline = this.state.tenders.deadline;
			tenderSource = this.state.tenders.tenderSource;
		}	

		console.log("This is to Check : " + name)
	
		
		 return(
			<div className={classes.layout}>

		 <Grid container>
              <Grid item md={6}>
				<div className={classes.imageLayout}>
					<img className={classes.img} alt="complex" src= {TenderImageFile} />
				</div>
			  </Grid>
			  <Grid item md={6}>
				  <br/>
			  <div className={classes.eventBody}>
			  		<h2>Title: {name}</h2>
					<Alert variant="outlined" severity="info">
						Deadline: <SimpleDateTime dateFormat="DMY" dateSeparator="/"  timeSeparator=":">{deadline}</SimpleDateTime>
					</Alert>
					<br />
					<p>Source: {tenderSource}</p>

					<p>Code: {code}</p>
					<p>{documentToReactComponents(description, renderOptions)}</p>
				</div>
				<div className={classes.socialShare}>
					<h5>Share with</h5>
						<SimpleShareButtons
							url = {"https://mof-gov-tl.netlify.app/publicationdetails/"+ slug }
							size="35px"
						/>
				</div>
			  </Grid>
			</Grid>
			<br/>
			</div>
		);
	}
}

TenderDetails.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(TenderDetails);




