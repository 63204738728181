import React, { useRef } from 'react';
import {useState} from 'react'
import "./FeedBack.css";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

const FeedBack = () => {   
  const form = useRef();
  const [modal, SetModal]= useState(false);
  const SERVICE_ID = "service_r14s0um";
  const TEMPLATE_ID = "template_797nck5";
  const USER_ID = "nM4NdAzTdJgzSQ6_i";


// Send data to Email
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID,form.current,USER_ID)
      .then(
        (result) => {
         // alert('message sent successfully...');
          console.log(result.text);
          Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully'
          })
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: 'error',
            title: 'Ooops, Something Went Wrong',
            text: error.text,
          })
        }
      );
      e.target.reset();
  };
  
  return (
<div>
{!modal && <button className="bt-feedback" onClick={() =>SetModal((value) => !value)}>
Contact us 
</button>
}
        <div>
          {modal &&(<form className="feedbackForm right" ref={form} onSubmit={sendEmail}>
          
          <h1 className="titleform">Contact Us<button className="btn-close-form" onClick={() =>SetModal((value) => !value)}>X</button></h1>
          
        <div className='half'>
          <input type='text' required placeholder='Please enter your full name' name='user_name' />
       </div>
        <div className='half'>
          <input type='email' required placeholder='Please enter your email address' name='user_email' />
        </div>
        <div className='half'>
        <textarea name='message' type='text' required placeholder='Please enter your message here'></textarea>
        </div>
        <input type='submit' value='Submit' id='input-submit' />
        
      </form>)
      
    }
      </div>
      </div>
  );
}

export default FeedBack;
