import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import client from '../Contentful_API/api';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import "./formerminister.css"
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
});

function FormerMinister(props) {
  const { push } = useHistory()
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12}>       
            <Grid container spacing={2}>
                <Grid item  xs={12}>
                    <Typography  color="white" gutterBottom>
                        <CardActionArea component="a" href="#">
                            <Card className={classes.card}>
                            <CardMedia className={classes.cardMedia} image={props.formerMinisters.fields.profilepicture.fields.file.url} title="ss" />
                            <div className={classes.cardDetails}>
                                <CardContent>
                                <Typography component="h2" variant="h5">
                                   {props.formerMinisters.fields.completename}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                {props.formerMinisters.fields.yearofservice}
                                </Typography>
                                <Typography variant="subtitle1" paragraph className="text-wrap">
                                    {documentToReactComponents(props.formerMinisters.fields.achievements)}
                                </Typography>
                                <Typography variant="subtitle1" color="primary">
                                    <Button variant="contained" color="primary"  style={{background: '#530052'}}  
                                        onClick={() => push({pathname: '/formerministerdetails/'+props.formerMinisters.fields.slug})}>
                                        Continue reading...
                                    </Button>
                                </Typography>
                                </CardContent>
                            </div>
                            </Card>
                        </CardActionArea>
                        </Typography>
                    </Grid>
                </Grid>
        <br/>
    <br/>
    </Grid>
  );
}

class FormerMinisterLists extends Component {

    state = {
        formerMinisters: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getFormerMinister()
    }
    getFormerMinister = () => {
        client.getEntries({
            content_type: 'formerMinisters',
            query: this.state.searchString,
            order: 'fields.assignmentorder'
        })
        .then((response) => {
            this.setState({formerMinisters: response.items})
            console.log(this.state.formerMinisters)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getFormerMinister()
    }
    render() {
        return (
            <div>
                <Card>
                    <CardContent>   
                        { this.state.formerMinisters ? (
                            <div>
                                <div className="topInfo">
                                <Typography variant="h5" component="h2">
                                    List of Former Ministers
                                </Typography>
                                <Typography  color="white" gutterBottom>
                                    Find all previous Ministers of Finance all time. From time to time, each minister has contributed to the development of Timor-Leste through the role Minister of Finance. Some of them have contributed to ensure a vibrant process of Public Financial Management of the Timor-Leste. See the profile of each minister below
                                </Typography>
                                <Typography variant="body2" component="p">
                                </Typography>
                            </div>
                            <Card style={{ border: "none", boxShadow: "none", borderRadius:"0%", background: '#B6D0E2', backgroundSize: 'cover', position: 'relative', backgroundPosition: 'center'}}>
                                <CardContent>
                                    <TextField style={{padding: 24}}
                                        id="searchInput"
                                        placeholder="Search for Former Minister"   
                                        margin="normal"
                                        onChange={this.onSearchInputChange}
                                        />
                                        { this.state.formerMinisters.map(formerMinister => (
                                        
                                            <FormerMinister formerMinisters={formerMinister} />
                                        
                                        ))}
                                </CardContent>
                            </Card>
                            </div>
                        ) : "No Former Minister found" }
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default FormerMinisterLists
