import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import client from '../Contentful_API/api';
import CustomDataInfo from './CustomData'

class CustomRevenueListItem extends Component {

    state = {
        customrevenuecollections: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getCustomRevenueData()
    }
    getCustomRevenueData = () => {
        client.getEntries({
            content_type: 'customrevenuecollections',
            query: this.state.searchString
        })
        .then((response) => {
            this.setState({customrevenuecollections: response.items})
            console.log(this.state.customrevenuecollections)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getCustomRevenueData()
    }
    render() {
        return (
            <div>
                { this.state.customrevenuecollections ? (
                    <div>
                        <TextField style={{padding: 24}}
                            id="searchInput"
                            placeholder="Search for Custom Revenu Data"   
                            margin="normal"
                            onChange={this.onSearchInputChange}
                            />
                        <Grid container spacing={2} style={{padding: 24}}>
                            { this.state.customrevenuecollections.map(dd => (
                               <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <CustomDataInfo customrevenuecollections={dd} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "No Custom revenue Data found" }
            </div>
        )
    }
}

export default CustomRevenueListItem;