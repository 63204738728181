import React from 'react'
import {useHistory} from 'react-router-dom'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import './../../App.css'
import Divider from '@material-ui/core/Divider';
import Larinlau from "../StaticImages/larinlaulospalos.png"

const styles = {
    paperContainer: {
        backgroundImage: `url(${Larinlau})`
    }
};



export default function Event(props) {
    const { push } = useHistory()
    console.log(props)
    return(
        
        <div>
            { props.events ? (
                <Card style={{ border: "none", boxShadow: "none", borderRadius:"0%", background: '#B6D0E2'}}>
                    <CardMedia style={{height: 0, paddingTop: '35%'}}
                    image={props.events.fields.eventimage.fields.file.url}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="headline" justify="center">
                        {props.events.fields.title}
                    </Typography>
                    <Typography gutterBottom>
                            <Button variant="contained" color="primary"  style={{background: '#530052'}}  
                                onClick={() => push({pathname: '/eventdetails/'+props.events.fields.slug})}>
                                View Details
                            </Button>
                    </Typography>
                    </CardContent>
                    <Divider/>
                    <CardActions style={styles.paperContainer}>
                        
                    </CardActions>
                </Card>
            ) : null}
        </div>
    )
}
