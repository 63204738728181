import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(5),
    },
    large: {
        width: theme.spacing(40),
        height: theme.spacing(40),
      },
  },
}));

export default function ClientList(props) {
  const classes = useStyles();
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className={classes.root}>
            { props.menus ? (
               <Link onClick={() => openInNewTab(props.menus.fields.title)}>
                    <Avatar alt="Clients Logo" src={props.menus.fields.mediafile.fields.file.url} style={{ height: '100px', width: '100px' }}/>
                </Link>
            ): null }


    </div>
  );
}
