import React, { Component } from 'react'
import client from "../Contentful_API/api"
import ClientList from './ClientList'
import Carousel from 'react-elastic-carousel'


const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 }
  ];
  
class Partners extends Component { 
    
    state = {
        menus: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getMenuList()
    }
    getMenuList = () => {
        client.getEntries({
            content_type: 'menus', 'fields.type': 'clientpartner',
        })
        .then((response) => {
            this.setState({menus: response.items})
            console.log(this.state.menus)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })

    }
    render(props) {
        return (
            <div>   
                { this.state.menus ? (
                    <div>
                        <br/>
                        <Carousel itemsToShow={5} enableAutoPlay={true} showEmptySlots={true} breakPoints={breakPoints} pagination={false}>
                            { this.state.menus.map(menuList => (
                                <ClientList menus={menuList} />
                            ))}
                        </Carousel>
                    </div>
                ) : "No Menu found" }
            </div>
        )
    }
}

export default Partners;
