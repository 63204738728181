import React from "react";
import {
Box,
Container,
Row,
Column,
FooterLink,
Heading,
DevelopmentLink,
} from "./FooterStyles";

import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import UPULAKUAR from "./upulakuar.png"

const useStyles = makeStyles((theme) => ({
    divider: {
        backgroundImage: `url(${UPULAKUAR})`,
        height: "10px",
    },
  }));


function DividerBorder(){
    const classes = useStyles();

    return(
        <Divider className={classes.divider} />
    )
}

function Copyright() {
  return (
    <div style={{ background: "#313133",
		textAlign: "center",
		marginTop: "-40px",
		flex: 1,
		paddingTop: '5px',
		paddingBottom: '60px',
		}}>
    <DevelopmentLink>
	{'Copyright © '} Ministry of Finance, RDTL <br/>
      Developed and Maintained by IFMISU<br/>
	  {new Date().getFullYear()}
    </DevelopmentLink> 
    </div>
  );
}

const Footer = () => {
return (
  <div>
	<DividerBorder/>
	<Box>
	<h1 style={{ color: "black",
				textAlign: "center",
        marginTop: "-50px",
        }}>
		<i>"Good Bye Conflict, Welcome Development!"</i>
	</h1>
	<Container>
		<Row>
		<Column>
			<Heading>About Us</Heading>
			<FooterLink href="https://www.mof.gov.tl/pagedetails/organizational-structure-and-roles">Organisational Structure & Roles</FooterLink>
			<FooterLink href="https://www.mof.gov.tl/pagedetails/minister-of-finance">Minister of Finance</FooterLink>
			<FooterLink href="https://www.mof.gov.tl/pagedetails/vice-minister-of-finance">Vice-Minister of Finance</FooterLink>
			<FooterLink href="https://www.mof.gov.tl/pagedetails/legal-framework">Legal Framework</FooterLink>
			<FooterLink href="https://www.mof.gov.tl/pagedetails/our-research-statistics-and-indicators">Our Research, Statistics & Indicators Documents and Forms</FooterLink>
			<FooterLink href="https://www.g7plus.org/">g7+</FooterLink>
			
		</Column>
		<Column>

		</Column>
		<Column>
			<Heading>Services</Heading>
			<FooterLink href="https://attl.gov.tl/">Taxation</FooterLink>
			<FooterLink href="https://customs.gov.tl/">Customs</FooterLink>
			<FooterLink href="http://invoicetracking.mof.gov.tl/">Invoice Tracking</FooterLink>
			<FooterLink href="https://www.mof.gov.tl/budgetspendingpage">Budget & Spending</FooterLink>
			<FooterLink href="http://www.eprocurement.gov.tl/public/indexeprtl?&lang=en">eProcurement</FooterLink>
      		<FooterLink href="http://www.aidtransparency.gov.tl/">Aid Effectiveness</FooterLink>
			<FooterLink href="https://inetl-ip.gov.tl">Census and Statistics</FooterLink>
			<FooterLink href="http://transparency.gov.tl/english.html"> Portal Transparency</FooterLink>
			<FooterLink href="https://webmail.mof.gov.tl/owa/auth/logon.aspx?replaceCurrent=1&url=https%3a%2f%2fwebmail.mof.gov.tl%2fowa%2f">Webmail for Ministry Staff</FooterLink>
		</Column>
		
		
		</Row>
	</Container>
	</Box>
	<Copyright/>
  </div>
);
};
export default Footer;
