import React, { Component } from 'react'
import PropTypes from 'prop-types';
import client from '../Contentful_API/api';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles';
import Grid from '@material-ui/core/Grid';
import { SimpleShareButtons } from "react-simple-share";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import Button from '@material-ui/core/Button'


const renderOptions = {
	renderNode: {
	  [INLINES.EMBEDDED_ENTRY]: (node, children) => {
		if (node.data.target.sys.contentType.sys.id === "5j4CdX67k92G8DhB43JYEi") {
		  return (
			<a href={`/publicationdetails/${node.data.target.fields.slug}`}>            
				{node.data.target.fields.name}
			</a>
		  );
		}
	  },
	  [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
		if (node.data.target.sys.contentType.sys.id === "5j4CdX67k92G8DhB43JYEi") {
		  return (
			<pre>
			  <code>Test COde</code>
			</pre>
		  );
		}
	  },
  
	  [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
		return (
		  <div>
			  	<br/>
			  	<Button variant="contained" color="inherit" > 
					<a className="buttonLink" href={`https://${node.data.target.fields.file.url}`} donwload> Download Content [ENG, TL]</a> 
				</Button>
		  </div>
		  
		);
	  },
	},
  };

  
class SinglPublication extends Component {
	constructor(){
		super()
		this.state = {publications: null}
	}

	
	componentDidMount() {
   	 client.getEntries({content_type: 'publications', 'fields.slug': this.props.match.params.slug})
      .then(response => {
        return response.items[0].fields;
      })
      .then(fields => {
        this.setState({
        publications: fields
        });
      })
      .catch(console.error);
  }

	render(props){
		const {classes} = this.props;

		let title;
		let slug;
		let imageurl;
		let description;

		if (this.state.publications) {
      	title = this.state.publications.title;
		imageurl = this.state.publications.publicationmedia.fields.file.url; 
		slug = this.state.publications.slug;
		description = this.state.publications.description;
		
		}	

		 return(
			<div className={classes.layout}>
				<Grid container>
					<Grid item md={6}>
						<div className={classes.imageLayout}>
							<img className={classes.img} alt="complex" src= {imageurl} />
						</div>
					</Grid>
					<Grid item md={6}>
					<br/>
						<div className={classes.eventBody}>
							<h2>{title}</h2>
							<p>{documentToReactComponents(description,  renderOptions)}</p>				
						</div>
						<div className={classes.socialShare}>
							<h5>Share with</h5>
								<SimpleShareButtons
									url = {"https://www.mof.gov.tl/publicationdetails/"+ slug }
									size="35px"
								/>
						</div>
					</Grid>
				</Grid>
				<br/>
			</div>
		);
	}
}

SinglPublication.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(SinglPublication);




