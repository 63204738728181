import React, { Component } from 'react'
import Event from '../Events/Events';
import client from '../Contentful_API/api';
import Carousel from 'react-material-ui-carousel'

class Slider extends Component {

    state = {
        events: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getEvents()
    }
    getEvents = () => {
        client.getEntries({
            content_type: 'events',
            query: this.state.searchString,
            limit: 5
        })
        .then((response) => {
            this.setState({events: response.items})
            console.log(this.state.events)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getevents()
    }
    render() {
        return (
            <div>
                { this.state.events ? (
                    <div>
                         <Carousel>
                            { this.state.events.map(currentevents => (
                                <Event events={currentevents} />
                            ))}
                        </Carousel>
                    </div>
                ) : "No events found" }
            </div>
        )
    }
}

export default Slider;
