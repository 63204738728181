import { createClient } from 'contentful';

const SPACE_ID = '60pzqxyjaawg'
const ACCESS_TOKEN = 'E8Jscd9zRRUYIfU8uO62covijtHStgFLROeuMF5kx-U'

const client = createClient({
    space: SPACE_ID,
    accessToken: ACCESS_TOKEN
})


export default client;