import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import client from '../../Contentful_API/api';
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListItemIcon from '@material-ui/core/ListItemIcon';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));


function LinkList(props){
  const classes = useStyles();
    return(
        <div className={classes.root}>        
          <Link onClick={() => {window.location.href="/pagedetails/"+ props.menus.fields.slug}}> 
              <ListItemIcon>
                <ListAltIcon/>
                {props.menus.fields.title}
              </ListItemIcon>
          </Link>
        </div>
    )
}

class EconomyItems extends Component { 
    state = {
        menus: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getMenuList()
    }
    getMenuList = () => {
        client.getEntries({
            content_type: 'menus', 'fields.type': 'economy',
            order: 'fields.menuorder'
        })
        .then((response) => {
            this.setState({menus: response.items})
            console.log(this.state.menus)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })

    }
    render(props) {
        return (
            <div>
                { this.state.menus ? (
                    <div>
                        <Grid container spacing={-30} style={{padding: -4}}>
                            { this.state.menus.map(menuList => (
                               <Grid item xs={12} sm={6} lg={6} xl={6}>
                                    <LinkList menus={menuList} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "No Menu found" }
            </div>
        )
    }
}

export default EconomyItems;


