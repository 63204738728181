import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import BudgetItems from "../MenuItems/BudgetItems"

const styles = (theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing.unit,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: 600,
  },
})

const BudgetMenu = ({ classes }) => (
  <PopupState variant="popover" popupId="demoPopover">
    {(popupState) => (
      <div>
        <Typography {...bindHover(popupState)} variant="small">
            Budget & Spending
        </Typography>
        <HoverPopover
          {...bindPopover(popupState)}
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: { width: '100%' },
          }}
        >
          <Typography><BudgetItems/></Typography>
        </HoverPopover>
      </div>
    )}
  </PopupState>
)

BudgetMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(BudgetMenu)
