import React, { Component } from 'react'
import PropTypes from 'prop-types';
import client from '../Contentful_API/api';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles';
import Grid from '@material-ui/core/Grid';
import { SimpleShareButtons } from "react-simple-share";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



class SingleMenu extends Component {
	constructor(){
		super()
		this.state = {menus: null}
	}
	componentDidMount() {

   	 client.getEntries({content_type: 'menus',  'fields.slug': this.props.match.params.slug})
      .then(response => {
        return response.items[0].fields;
      })
      .then(fields => {
        this.setState({
        menus: fields
        });
      })
      .catch(console.error);
  }

	render(props){
			const {classes} = this.props;

			let slug;
			let imageurl;
			let description;

			if (this.state.menus) {
			imageurl = this.state.menus.mediafile.fields.file.url; 
			description = this.state.menus.description
			slug = this.state.menus.slug;
		
		}
		 return(
			<div className={classes.layout}>			
			 <Grid container>
              <Grid item md={8}>
				<div className={classes.imageLayout}>
					<img className={classes.img} alt="File Content" src= {imageurl} />
				</div>
				<div className={classes.socialShare}>
					<h5>Share with</h5>
					<SimpleShareButtons
						url = {"https://mof-gov-tl.netlify.app/publicationdetails/"+ slug }
						size="50px"
					/>
				</div>
			  </Grid>
			  <Grid item md={12}>
			  	<div className={classes.eventBody}>
					<p>{documentToReactComponents(description)}</p>						
				</div>
			  </Grid>
			</Grid>
			</div>
		);
	}
}

SingleMenu.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(SingleMenu);




