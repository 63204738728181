import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import client from '../Contentful_API/api';
import Poster from './Poster';


const Loader = () => (
    <div class="divLoader">
      <svg class="svgLoader" viewBox="0 0 100 100" width="10em" height="10em">
        <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
      </svg>
    </div>
  );

class PosterList extends Component {

    state = {
        postergraphics: [],
        searchString: '',
        loading: true,
    }
    constructor() {
        super()
        this.getPostergraphics()
    }
    getPostergraphics = () => {
        client.getEntries({
            content_type: 'postergraphics',
            query: this.state.searchString
        })
        .then((response) => {
            this.setState({postergraphics: response.items, loading: false})
            console.log(this.state.postergraphics)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getPostergraphics()
    }
    render() {
        return (
            <div>
                {this.state.loading ? <Loader /> : null}
                { this.state.postergraphics ? (
                    <div>
                        <TextField style={{padding: 24}}
                            id="searchInput"
                            placeholder="Search for Poster graphics"   
                            margin="normal"
                            onChange={this.onSearchInputChange}
                            />
                        <Grid container spacing={2} style={{padding: 24}}>
                            { this.state.postergraphics.map(currentpostergraphics => (
                               <Grid item xs={12} sm={4} lg={4} xl={4}>
                                    <Poster postergraphics={currentpostergraphics} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "No postergraphics found" }
            </div>
        )
    }
}

export default PosterList;