import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WorkIcon from '@material-ui/icons/Work';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import RecentPublication from '../Publications/RecentPublication';
import RecentTender from '../Tenders/RecentTender';
import RecentKnowledgeCenterList from '../KnowledgeCenter/RecentKnowledge';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import { useHistory } from 'react-router-dom';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import ApplicationList from '../Jobs/JobApplication';
import CurrencyConverter from '../Converter/Converter';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import InvoiceTrackingPortal from './InvoiceTracking';
import client from '../Contentful_API/api';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  header: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

export default function PublicationTab() {
  const { push } = useHistory();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [entriesCount, setEntriesCount] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getTenders = () => {
      client
        .getEntries({ content_type: 'tenders' })
        .then((response) => {
          const currentTenders = response.items;
          const liveTenders = currentTenders.filter((item) => new Date(item.fields.deadline) > new Date());
        
          setEntriesCount(liveTenders.length);
 
        })
        .catch((error) => {
          console.log('Error occurred while fetching Entries');
          console.error(error);
        });
    };

    getTenders();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Budget Books & Documents" icon={<MenuBookIcon />} {...a11yProps(0)} />
          <Tab label="Vacancy Announcement" icon={<Badge  color="secondary"><WorkIcon /></Badge>} {...a11yProps(1)} />
          <Tab label="Tender Publications" icon={<Badge badgeContent={entriesCount} color="secondary"><HomeWorkIcon /></Badge>} {...a11yProps(2)} />
          <Tab label="Invoice Tracking" icon={<ReceiptIcon />} {...a11yProps(3)} />
          <Tab label="Knowledge Center" icon={<CastForEducationIcon />} {...a11yProps(4)} />
          <Tab label="Currency Converter" icon={<MonetizationOnIcon />} {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <RecentPublication />
        <Button
          variant="contained"
          color="primary"
          style={{ background: '#530052' }}
          onClick={() => push({ pathname: '/publications/' })}
        >
          View all Publications:
        </Button>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ApplicationList />
        <Button
          variant="contained"
          color="primary"
          style={{ background: '#530052' }}
          onClick={() => push({ pathname: '/jobs/' })}
        >
          View all Jobs
        </Button>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <RecentTender />
        <Button
          variant="contained"
          color="primary"
          style={{ background: '#530052' }}
          onClick={() => push({ pathname: '/tenders/' })}
        >
          View all Tenders 
        </Button> {"    "}
        <Button
          variant="contained"
          color="primary"
          style={{ background: '#530052' }}
          onClick={() => push({ pathname: '/tenders_archive/' })}
        > 
          View Tender in Archive
        </Button>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InvoiceTrackingPortal />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <RecentKnowledgeCenterList />
        <Button
          variant="contained"
          color="primary"
          style={{ background: '#530052' }}
          onClick={() => push({ pathname: '/allknowledge' })}
        >
          View all Knowledge
        </Button>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <CurrencyConverter />
      </TabPanel>
    </div>
  );
}
