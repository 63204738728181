import "./styles.css";
import React, { Component } from 'react'
import client from '../Contentful_API/api';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import CardHeader from '@material-ui/core/CardHeader';


const TableStyles = makeStyles({
  table: {
    minWidth: 1200,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 400,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  sizeAvatar: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    backgroundColor: 'green',
    fontSize: 12,
  },

}));


function HigherEducationBudgetYear(props){
  const Currencyformatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const classes = useStyles();
  const newClasses = TableStyles();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  return(
    <div>
      <Card className={classes.root} variant="outlined">
        <CardHeader
          avatar={
            <Avatar className={classes.sizeAvatar}>{props.highereducationbudgets.fields.year}</Avatar>
          }
          title="Ministry of Higher Education, Science and Culture Including SEAC"
          subheader="Data source Budget Transparency Portal"
        />
      <CardContent>
        <Typography variant="body2" component="p">
          <Card className={classes.root}>
            <CardContent>
              <Typography variant="h4" component="h4">
              {Currencyformatter.format(props.highereducationbudgets.fields.budget_expenditure)} 
              </Typography>
            </CardContent>
          </Card>
        </Typography>
      </CardContent>
      <CardActions>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        View Details
      </Button>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
        <DialogTitle id="responsive-dialog-title">{props.highereducationbudgets.fields.year + " Budget Expendiure and Revenue Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          <TableContainer component={Paper}>
                <Table className={newClasses.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Budget</TableCell>
                      <TableCell align="right">Commitments</TableCell>
                      <TableCell align="right">Obligations</TableCell>
                      <TableCell align="right">Actual</TableCell>
                      <TableCell align="right">Balance</TableCell>
                      <TableCell align="right">Execution %</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Expenditure
                        </TableCell>
                        <TableCell align="right"><Chip label={Currencyformatter.format(props.highereducationbudgets.fields.budget_expenditure)} color="primary"/></TableCell>
                        <TableCell align="right">{Currencyformatter.format(props.highereducationbudgets.fields.commitments_expenditure)}</TableCell>
                        <TableCell align="right">{Currencyformatter.format(props.highereducationbudgets.fields.obligations_expenditure)}</TableCell>
                        <TableCell align="right">{Currencyformatter.format(props.highereducationbudgets.fields.actual_expenditure)}</TableCell>
                        <TableCell align="right">{Currencyformatter.format(props.highereducationbudgets.fields.balance_expenditure)}</TableCell>
                        <TableCell align="right"><Chip label={props.highereducationbudgets.fields.execution_expenditure} color="primary"/></TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Download
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      </CardActions>
    </Card>
    </div>
  )
}


class HigherEducationBudget extends Component{
  //initiate the state
  state = {
    highereducationbudgets: []
  }

  //create a constructor
  constructor(){
    super()
    this.getHigherEducationBudgets()
  }

  //get Infographic Collections using getEntries method
  getHigherEducationBudgets = () => {
    client.getEntries({
      content_type: 'highereducationbudgets',
      query: this.state.searchString,
      order: 'fields.year',
      limit: 5
    })
    .then((response) => {
        this.setState({highereducationbudgets: response.items})
        console.log(this.state.highereducationbudgets)
    })
    .catch((error) => {
      console.log("Error occurred while fetching Entries")
      console.error(error)
    })
}

render(props){
        return (
        <div>
          <Grid container spacing={2} style={{padding: 24}}>
            {
              this.state.highereducationbudgets.map(data=>(
              <Grid item xs={12} sm={6} lg={6} xl={6}>
                <HigherEducationBudgetYear highereducationbudgets={data}/>
              </Grid>
            ))
            }
          </Grid>
        </div>
      )
    }
}

export default HigherEducationBudget;
