import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TimorianaSlider from '../Slider/Slider'
import Service from "./Service";
import PublicationTab from './PublicationTab'
import Partners from "../Partners/Partner"
import InfoTab from "../InfoGraphic/InfoTab"
import PopupNews from "../PopupNotifications/Popup"

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));



export default function Home() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        <div className={classes.heroContent}>
        <TimorianaSlider/>
          <Container maxWidth="lg">
           
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="lg">
         <PopupNews/>
        <Typography variant="h5" align="center" color="textPrimary" paragraph>
            Recent Publications
        </Typography>
        <PublicationTab/>
        </Container>
        <Container>
        <Typography variant="h5" align="center" color="textPrimary" paragraph>
            Info Graphic & Specific Dataset
        </Typography>
        <InfoTab/>
        </Container>
        <Service/>
        <Partners/>
      </main>
   
    </React.Fragment>
  );
}
