import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import client from '../Contentful_API/api';
import Directorate from './Directorate';

class DirectorateList extends Component {

    state = {
        directorates: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getDirectorates()
    }
    getDirectorates = () => {
        client.getEntries({
            content_type: 'directorates',
            query: this.state.searchString
        })
        .then((response) => {
            this.setState({directorates: response.items})
            console.log(this.state.directorates)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getDirectorates()
    }
    render() {
        return (
            <div>
                { this.state.directorates ? (
                    <div>
                        <TextField style={{padding: 24}}
                            id="searchInput"
                            placeholder="Search for Directorates"   
                            margin="normal"
                            onChange={this.onSearchInputChange}
                            />
                        <Grid container spacing={2} style={{padding: 24}}>
                            { this.state.directorates.map(dir => (
                               <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Directorate directorates={dir} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "No Directorate found" }
            </div>
        )
    }
}

export default DirectorateList;