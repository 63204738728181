import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import client from '../Contentful_API/api';
import Publication from './Publication';

import Loader from '../Loader/Loader';

class RecentPublication extends Component {

    state = {
        publications: [],
        searchString: '',
        loading: true,
    }
    constructor() {
        super()
        this.getPublications()
    }
    getPublications = () => {
        client.getEntries({
            content_type: 'publications',
            query: this.state.searchString,
            limit: 8
        })
        .then((response) => {
            this.setState({publications: response.items, loading: false})
            console.log(this.state.publications)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
    }
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getPublications()
    }
    render() {
        return (
            <div>
                {this.state.loading ? <Loader /> : null}
                { this.state.publications ? (
                    <div>
                        <Grid container spacing={2} style={{padding: 24}}>
                            { this.state.publications.map(currentPublications => (
                               <Grid item xs={12} sm={3} lg={3} xl={3}>
                                    <Publication publications={currentPublications} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                ) : "No Publications found" }
            </div>
        )
    }
}

export default RecentPublication;