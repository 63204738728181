import React, { Component } from 'react'
import PropTypes from 'prop-types';
import client from '../Contentful_API/api';
import { withStyles } from '@material-ui/core/styles';
import styles from './Styles';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button'
import { SimpleShareButtons } from "react-simple-share";

const SocialShare = () => (
    <SimpleShareButtons
      whitelist={["Facebook", "Twitter", "LinkedIn", "Google+"]}
      size="30px"
    />
  );

class SingleMinisterDetails extends Component {
	constructor(){
		super()
		this.state = {formerMinisters: null}
	}

	componentDidMount() {
   	 client.getEntries({content_type: 'formerMinisters', 'fields.slug': this.props.match.params.slug})
      .then(response => {
        return response.items[0].fields;
      })
      .then(fields => {
        this.setState({
        formerMinisters: fields
        });
      })
      .catch(console.error);
  }

	render(props){
		const {classes} = this.props;

		let completename;
		let achievements;
		let imageurl;
		let yearofservice;

		if (this.state.formerMinisters) {
        completename = this.state.formerMinisters.completename;
		achievements = this.state.formerMinisters.achievements;
		yearofservice = this.state.formerMinisters.yearofservice
		imageurl = this.state.formerMinisters.profilepicture.fields.file.url; 
		}	
		 return(
			<div className={classes.layout}>
			 <Grid container>
				<br/>
				<Card>
					<CardContent>
						<div className="topInfo">
							<Grid container spacing={2} style={{padding: 24}}>
								<Grid item xs={12} sm={3} lg={3} xl={3}>
									<Avatar src={imageurl} style={{ height: '200px', width: '200px' }}/>
								</Grid>
								<Grid item xs={12} sm={3} lg={3} xl={3}>
								<h2>{completename}</h2>
								{yearofservice}
								<br/>
								<SocialShare/>
								<br/>
									<Button variant="contained" color="primary" href="/formerministers">
										Back
									</Button>
								</Grid>
								
							</Grid>
						</div>
					<Grid item md={12}>
						<p>{documentToReactComponents(achievements)}</p>
					</Grid>
					</CardContent>	
				</Card>
				<br/>
			</Grid>
			</div>
		);
	}
}

SingleMinisterDetails.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(SingleMinisterDetails);
