import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state'
import AboutItems from "../MenuItems/AboutItems"

const styles = (theme) => ({
  popover: {
    pointerEvents: 'none',
    padding: '10px',
    marginBottom: '10px',
  },
  paper: {
    padding: theme.spacing.unit,
    border: "1px solid black"
  },
})

const AboutMenu = ({ classes }) => (
  <PopupState variant="popover" popupId="demoPopover">
    {(popupState) => (
      <div>
        <Typography 
        {...bindHover(popupState)}
        variant="small">
            About
        </Typography>
        <HoverPopover
          {...bindPopover(popupState)}
          className={classes.popover}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          PaperProps={{
            style: {
              width: '50%',
              overflow: 'true',
              //transform: 'translateX(-20%) translateY(4%)',
              //pointerEvents: "none",
            }
          }}
        >
          <Typography>
              <AboutItems/>
          </Typography>
        </HoverPopover>
      </div>
    )}
  </PopupState>

)

AboutMenu.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AboutMenu)
