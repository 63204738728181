// src/ListView.js
import React, { useState } from 'react';
import Chip from '@material-ui/core/Chip';
import { Public } from '@material-ui/icons';
// import { Link } from 'react-router-dom';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ITEMS_PER_PAGE = 20;

const ListView = ({ items }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(items.length / ITEMS_PER_PAGE);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const currentItems = items.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <>
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {currentItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Link href={`/eventdetails/${item.fields.slug}`}>
                  <Avatar
                    alt={item.fields.title}
                    src={item.fields.eventimage.fields.file.url}
                    variant='rounded'
                    style={{paddingRight:'10px'}}
                    sx={{ width: 110, height: 70 }}
                  />
                </Link>
              </ListItemAvatar>
              <Typography noWrap> </Typography>
              <ListItemText
                primary= {
                  <Link href={`/eventdetails/${item.fields.slug}`} underline="hover">
                      {item.fields.title}
                    </Link>
                } 
                secondary={
                  <>
                    <Chip
                      icon={<Public />}
                      label={item.fields.publicationyear}
                      clickable
                      color="info"
                    />
                    <React.Fragment> </React.Fragment>
                    <Link href={`/eventdetails/${item.fields.slug}`}>
                      <Button variant="contained" style={{ background: '#530052' }}>View</Button>
                    </Link>
                  </>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</Button>
        <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</Button>
      </div>
    </>
  );
};

export default ListView;
