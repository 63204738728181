// src/TenderList.js
import React, { Component } from 'react';
import client from '../Contentful_API/api';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import SearchBar from './SearchBar';
import ListView from './ListView';
import Loader from '../Loader/Loader';

class TenderArciveList extends Component {
  state = {
    tenders: [],
    searchString: '',
    loading: true,
  };

  componentDidMount() {
    this.getTenders();
  }

  getTenders = () => {
    client
      .getEntries({
        content_type: 'tenders',
        query: this.state.searchString,
      })
      .then((response) => {
        this.setState({ tenders: response.items, loading: false });
        console.log(this.state.tenders);
      })
      .catch((error) => {
        console.log('Error occurred while fetching Entries');
        console.error(error);
      });
  };

  handleSearchChange = (searchString) => {
    this.setState({ searchString }, this.getTenders);
  };

  render() {
    const muiBaseTheme = createMuiTheme();
    const theme = {
      overrides: {
        MuiCard: {
          root: {
            '&.MuiEngagementCard--01': {
              transition: '0.3s',
              maxWidth: 1100,
              margin: 'auto',
              boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
              '&:hover': {
                boxShadow: '0 30px 70px -12.125px rgba(0,0,0,0.5)',
              },
              '& .MuiCardMedia-root': {
                paddingTop: '56.25%',
              },
              '& .MuiCardContent-root': {
                textAlign: 'left',
                padding: muiBaseTheme.spacing.unit * 3,
              },
              '& .MuiDivider-root': {
                margin: `${muiBaseTheme.spacing.unit * 3}px 0`,
              },
              '& .MuiTypography--heading': {
                fontWeight: 'bold',
              },
              '& .MuiTypography--subheading': {
                lineHeight: 1.8,
              },
              '& .MuiAvatar-root': {
                display: 'inline-block',
                border: '2px solid white',
                '&:not(:first-of-type)': {
                  marginLeft: -muiBaseTheme.spacing.unit,
                },
              },
            },
          },
        },
      },
    };

    return (

      <div className="container-fluid py-4">
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <Card className={'MuiEngagementCard--01'}>
            <CardContent>
              <SearchBar value={this.state.searchString} onChange={this.handleSearchChange} /></CardContent>
            <CardContent>
              
              {this.state.loading ? (
                < Loader />
              ) : (
                <ListView items={this.state.tenders} />
              )}
            </CardContent>
          </Card>
        </MuiThemeProvider>
      </div>
      
    );
  }
}

export default TenderArciveList;
