import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { SimpleShareButtons } from "react-simple-share";
import {Link} from 'react-router-dom'
import "./styles.css";

const SocialShare = () => (
    <SimpleShareButtons
      whitelist={["Facebook", "Twitter", "LinkedIn", "Google+"]}
      size="20px"
    />
  );


const muiBaseTheme = createMuiTheme();
const theme = {
  overrides: {
    MuiCard: {
      root: {
        "&.MuiEngagementCard--01": {
          transition: "0.3s",
          maxWidth: 300,
          margin: "auto",
          boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          "&:hover": {
            boxShadow: "0 30px 70px -12.125px rgba(0,0,0,0.5)"
          },
          "& .MuiCardMedia-root": {
            paddingTop: "56.25%"
          },
          "& .MuiCardContent-root": {
            textAlign: "left",
            padding: muiBaseTheme.spacing.unit * 3
          },
          "& .MuiDivider-root": {
            margin: `${muiBaseTheme.spacing.unit * 3}px 0`
          },
          "& .MuiTypography--heading": {
            fontWeight: "bold"
          },
          "& .MuiTypography--subheading": {
            lineHeight: 1.8
          },
          "& .MuiAvatar-root": {
            display: "inline-block",
            border: "2px solid white",
            "&:not(:first-of-type)": {
              marginLeft: -muiBaseTheme.spacing.unit
            }
          }
        }
      }
    }
  }
};

export default function Knowledge(props) {
  return (
      props.knowlegeCenter? (
        <MuiThemeProvider theme={createMuiTheme(theme)}>
        <div className="App">
        <Link onClick={() => {window.location.href="/knowledgedetails/"+ props.knowlegeCenter.fields.slug}} style={{ textDecoration: 'none' }}>
          <Card className={"MuiEngagementCard--01"}>
            <CardMedia
              image={props.knowlegeCenter.fields.knowledgeimage.fields.file.url}
            />
            <CardContent>
              <Typography
                 className="text"
                variant={"h6"}
                gutterBottom
              >
                  {props.knowlegeCenter.fields.title}
              </Typography>
              <Typography
                className={"MuiTypography--subheading"}
                variant={"caption"}
              >
              </Typography>
              <Divider light />
              <SocialShare
                    url = {"https://www.mof.gov.tl/knowledgedetails/" + props.knowlegeCenter.fields.slug }
                />
            </CardContent>
          </Card>
          </Link>
        </div>
      </MuiThemeProvider>
      ) : "No Knowledge Center Content" 
  )
}


