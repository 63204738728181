import React, { Component } from 'react'
import PropTypes from 'prop-types';
import client from '../Contentful_API/api';
import { withStyles } from '@material-ui/core/styles';
import { SimpleShareButtons } from "react-simple-share";
import styles from './Styles';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
// import Button from '@material-ui/core/Button';
// import baseURL from '../strapi_API/strapiBaseURL';

const renderOptions = {
	
	renderNode: {
	  [INLINES.EMBEDDED_ENTRY]: (node, children) => {
		if (node.data.target.sys.contentType.sys.id === "5j4CdX67k92G8DhB43JYEi") {
		  return (
			<a href={`/publicationdetails/${node.data.target.fields.slug}`}>            
				{node.data.target.fields.name}
			</a>
		  );
		}
	  },
	  [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
		if (node.data.target.sys.contentType.sys.id === "5j4CdX67k92G8DhB43JYEi") {
		  return (
			<pre>
			  <code>Test COde</code>
			</pre>
		  );
		}
	  },
  
	  [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
		return (

			<div style={{alignSelf: 'Right', backgroundSize: 'cover',marginLeft: '200px', marginRight: 'auto', }}>
			<img  src={`https://${node.data.target.fields.file.url}`} alt={node.data.target.fields.description} width={'650px'} height={'350px'}  />
			<br/>
			<br/>
		  </div>
		  
		  
		);
	  },
	},
  };

class SingleEvent extends Component {
	constructor(){
		super()
		this.state = {events: null}
	}

	componentDidMount() {
   	 client.getEntries({content_type: 'events', 'fields.slug': this.props.match.params.slug})
      .then(response => {
        return response.items[0].fields;
      })
      .then(fields => {
        this.setState({
          events: fields
        });
      })
      .catch(console.error);
  }

	render(props){
		const {classes} = this.props;
    	let title;
		let description;
    	let slug;
		let imageurl;
		if (this.state.events) {
      	title = this.state.events.title;
      	slug = this.state.events.slug;
		description = this.state.events.description;
        imageurl = this.state.events.eventimage.fields.file.url; 
		}	
		 return(
			<div className={classes.layout}>
				<HelmetProvider>
					<Helmet defer={false}>
						<title>{title}</title>
						<meta property="og:title" content={title} />
						<meta property="og:image" content={imageurl} />
						<meta property="description" content={description} />
					</Helmet>
				</HelmetProvider>
			 <Grid container>
              <Grid item md={8}>
				<div className={classes.imageLayout}>
					<img className={classes.img} alt="complex" src= {imageurl} />
				</div>
				
				<div className={classes.socialShare}>
					<h5>Share with</h5>
					<SimpleShareButtons
						url = {"https://www.mof.gov.tl/details/"+ slug }
						size="50px"
					/>
				</div>
			  </Grid>
			  <Grid item md={12}>
			  <div className={classes.eventBody}>
			  		<h2>{title}</h2>
					  <p>{documentToReactComponents(description, renderOptions)}</p>
				</div>
			  </Grid>
			</Grid>
			</div>
		);
	}
}

SingleEvent.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(SingleEvent);
