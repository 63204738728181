import React from 'react';


export default function PopupNews(props) {
  return (
        <div>
            <body>
                <script type="text/javascript"  src="https://apiv2.popupsmart.com/api/Bundle/384703" async></script>
            </body>
        </div>
  )
}
