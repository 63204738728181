// src/SearchBar.js
import React from 'react';
// import { TextField } from '@material-ui/core';
import TextField from '@mui/material/TextField';

const SearchBar = ({ value, onChange }) => {
  return (
    <TextField  label="Search"  fullWidth  variant="outlined" id="fullWidth" onChange={e => onChange(e.target.value)} color="secondary"    />
   
  );
};

export default SearchBar;
