// src/ListView.js
import React from 'react';
import Chip from '@material-ui/core/Chip';
import { Alarm, Input } from '@material-ui/icons';
import SimpleDateTime from 'react-simple-timestamp-to-date';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';


const ListView = ({ items }) => {

  return (
    <>
    
   
    
    
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

    {items.map((item, index) => (
        
            <>
            <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt="Travis Howard" src="https://cdn-icons-png.freepik.com/256/7034/7034357.png?ga=GA1.1.1412513962.1710602508&semt=ais_hybrid" />
            </ListItemAvatar>
            <ListItemText
              primary={item.fields.name}
              secondary={
                <>
                <React.Fragment>
                  <Chip
                        icon={<Input />}
                        label={item.fields.tenderSource} 
                        clickable
                        color="info"
                    /> 
                </React.Fragment>
                <React.Fragment> </React.Fragment>
                <React.Fragment>
                <Chip
                      icon={<Alarm />}
                      label={<SimpleDateTime dateSeparator="-" format="MYD" showTime="0">{item.fields.deadline}</SimpleDateTime>} 
                      clickable
                      color="primary"
                  />
                </React.Fragment>
                <React.Fragment> </React.Fragment>
                <React.Fragment>
                  <Link to={`/tenderdetails/${item.fields.slug}`}  >
                  <Button variant="contained" style={{ background: '#530052' }}>View</Button>
                  </Link>
                </React.Fragment>
                </>
                
              }
            />
            
            
          </ListItem>
          <Divider variant="inset" component="li" />
            </>
            
        
      ))}
        
      
    </List>
    </>
  );
};

export default ListView;
