import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import Grid from '@material-ui/core/Grid';
import client from '../Contentful_API/api';
import { styled } from '@mui/material/styles';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1), 
  width: '100%',
  textAlign: 'center', // Center text horizontally
  display: 'flex',     // Flexbox layout
  justifyContent: 'center', // Center content horizontally
  alignItems: 'center', // Center content vertically
  
}));

class RevenueExpenditure extends Component {
  // initiate the state
  state = {
    infographics: []
  }

  // create a constructor
  constructor() {
    super();
    this.getInfographics();
  }

  // get Infographic Collections using getEntries method
  getInfographics = () => {
    client.getEntries({
      content_type: 'infographics',
      query: this.state.searchString,
      order: '-fields.year',
      limit: 10
     
    })
    .then((response) => {
      this.setState({ infographics: response.items });
      console.log(this.state.infographics);
    })
    .catch((error) => {
      console.log("Error occurred while fetching Entries");
      console.error(error);
    });
  }

  render() {
    // Construct the result array dynamically from the state
    const result = [['Year', 'Expenditure', 'Revenue']];
    this.state.infographics.forEach(item => {
      // const { year, expenditure, revenue } = item.fields; 
      result.push([item.fields.year, item.fields.budget_expenditure, item.fields.budgetRev]);
    });

    return (
      <div>
        <Grid container spacing={1} style={{ padding: 3,}}>
        <Div>
          <center>Timor-Leste Budget Expenditure & Revenues<br/>
            <small>Data source Budget Transparency Portal</small>
          </center>
          </Div>
          <Chart chartType="ColumnChart" width="100%" height="400px" data={result} />
        </Grid>
      </div>
    );
  }
}

export default RevenueExpenditure;
