import React, {Component} from 'react';
import Container from '@material-ui/core/Container';

class Programs extends Component {
    
    render() {
        return(
            <Container>
                <p>Hello, hau mai hosi Komponente Programs!</p>
            </Container>
        ) 
    }
}

export default Programs;