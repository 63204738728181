// src/ListView.js
import React from 'react';
import Chip from '@material-ui/core/Chip';
import { FolderOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';



const ListView = ({ items }) => {

  return (
    <>
    
   
    
    
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

    {items.map((item, index) => (

            <>
            <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Link to={`/publicationdetails/${item.fields.slug}`}  >
                <Avatar alt={item.fields.title} 
                  src={item.fields.publicationmedia.fields.file.url} 
                  variant='rounded' 
                  sx={{ width: 110, height: 70 }}
                  style={{ paddingRight:'10px'}} />
              </Link>
            </ListItemAvatar>
          
            <ListItemText
              primary={item.fields.title}
              secondary={
                <>

                <React.Fragment>
                <Chip
                      icon={<FolderOutlined />}
                      label= {item.fields.publicationCategory}
                      clickable
                      color="info"
                  />
                </React.Fragment>
                <React.Fragment> </React.Fragment>
                <React.Fragment>
                  <Link to={`/publicationdetails/${item.fields.slug}`}  >
                  <Button variant="contained" style={{ background: '#530052' }}>View</Button>
                  </Link>
                </React.Fragment>
                </>
              }
            />
            
          </ListItem>

          <Divider variant="inset" component="li" />
            </>
            
   
      ))}
        
      
    </List>
    </>
  );
};

export default ListView;
