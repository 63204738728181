import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import client from '../../Contentful_API/api';
import CardMedia from '@material-ui/core/CardMedia'
import {Link} from 'react-router-dom'
import PreviousMinister from "../../StaticImages/Previous.png"

function LinkList(props){
    
    return(
        <div>
            <Link onClick={() => {window.location.href="/pagedetails/"+ props.menus.fields.slug}}>
                    <CardMedia style={{ paddingTop: '40%',
                        borderRadius: '3%',
                        margin: '10px'}}
                        image={props.menus.fields.mediafile.fields.file.url}
                        />{props.menus.fields.title}
            </Link>
        </div>
    )
}

function FormerMinisterLink(props){
    
    return(
        <div>
            <Link onClick={() => {window.location.href="/formerministers"}}>
                    <CardMedia style={{ paddingTop: '40%',
                        borderRadius: '3%',
                        margin: '10px'}}
                        image={PreviousMinister}
                        />Former Ministers of Finance
            </Link>
        </div>
    )
}

class AboutItems extends Component { 
    
    state = {
        menus: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getMenuList()
    }
    getMenuList = () => {
        client.getEntries({
            content_type: 'menus', 'fields.type': 'about',
            order: 'fields.menuorder'
        })
        .then((response) => {
            this.setState({menus: response.items})
            console.log(this.state.menus)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })

    }
    render(props) {
        return (
            <div>
                { this.state.menus ? (
                    <div>
                        <Grid container spacing={2} style={{padding: 24}}>
                            { this.state.menus.map(menuList => (
                               <Grid item xs={12} sm={4} lg={4} xl={4}>
                                    <LinkList menus={menuList} />
                                </Grid>
                            ))}
                        <FormerMinisterLink/>
                        </Grid>
                    </div>
                ) : "No Menu found" }
            </div>
        )
    }
}

export default AboutItems;


