import React, {Component} from 'react';
import Container from '@material-ui/core/Container';
import DirectorateList from '../Directorate/DirectorateList'
import client from '../Contentful_API/api';
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { SimpleShareButtons } from "react-simple-share";
import CardActions from '@material-ui/core/CardActions'
import "./styles.css";
import Larinlau from "../StaticImages/larinlaulospalos.png"
import {Link} from 'react-router-dom'

const styles = {
    paperContainer: {
        backgroundImage: `url(${Larinlau})`
    }
};

const SocialShare = () => (
    <SimpleShareButtons
      whitelist={["Facebook", "Twitter", "LinkedIn", "Google+"]}
      size="20px"
    />
  );

function LinkList(props) {
  return (
      props.menus? (
        <div>
            <Link onClick={() => {window.location.href="/pagedetails/"+ props.menus.fields.slug}} style={{ textDecoration: 'none' }}>
                <Card style={{ border: "none",  borderRadius:"3%", background: '#B6D0E2', position: 'center', backgroundPosition: 'center'}}>
                    <CardMedia style={{height: 4, paddingTop: '50%'}}
                    image={props.menus.fields.mediafile.fields.file.url}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="headline" justify="center">
                        {props.menus.fields.title}
                    </Typography>
                    <SocialShare/>
                    </CardContent>
                    <CardActions style={styles.paperContainer}>
                        
                    </CardActions>
                </Card>
            </Link>
        </div>
      ) : "Please wait while data is being fetched" 
  )
}



class Profile extends Component {

    state = {
        menus: [],
        searchString: ''
    }
    constructor() {
        super()
        this.getExecutives()
    }
    getExecutives = () => {
        client.getEntries({
            content_type: 'menus', 'fields.type': 'about',
            order: 'fields.menuorder',
            limit: 3
        })
        .then((response) => {
            this.setState({menus: response.items})
            console.log(this.state.menus)
        })
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })

    }
        
    render() {
        return(
                <Container>
                    <br/>
                    { this.state.menus ? (
                        <div class="cards">
                            { this.state.menus.map(menuList => (
                                <div class="card">
                                    <LinkList menus={menuList} />
                                </div>
                            ))}
                        </div>
                    ) : "No Menu found" }

                <DirectorateList/>
                </Container>
        ) 
    }
}

export default Profile;
