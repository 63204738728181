import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Pagination, RefinementList  } from 'react-instantsearch-dom';
import { MuiThemeProvider} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import {Link} from 'react-router-dom'
const searchClient = algoliasearch('S7R1KRS1XF', '7fab6447a1fd2e54e5db72574de2a79a');



function TitleContent({ hit }) {
  const content_type = JSON.stringify(hit.sys.contentType.sys.id).replace(/['"]+/g, '')
    return(
      <div>
        {(() => {
        if (content_type === "publications") {
          return (
            <div>
              {JSON.stringify(hit.fields.title).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}
              <br/>
              { <Link onClick={() => {window.location.href="/publicationdetails/"+ JSON.stringify(hit.fields.slug).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}}>view details</Link>}
              </div>
          )
        } else if (content_type === "events") {
          return (
            <div>
              {JSON.stringify(hit.fields.title).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}
              <br/>
              { <Link onClick={() => {window.location.href="/eventdetails/"+ JSON.stringify(hit.fields.slug).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}} >view details</Link>}
            </div>
          )
        } else if (content_type === "menus") {
          return (
            <div>
              {JSON.stringify(hit.fields.title).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}
              <br/>
              { <Link onClick={() => {window.location.href="/pagedetails/"+ JSON.stringify(hit.fields.slug).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}} >view details</Link>}
            </div>
          )
        } else if (content_type === "tenders") {
          return (
            <div>
              {JSON.stringify(hit.fields.name).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}
              <br/>
              { <Link onClick={() => {window.location.href="/tenderdetails/"+ JSON.stringify(hit.fields.slug).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}} >view details</Link>}
            </div>
          )
        } else if (content_type === "formerMinisters") {
          return (
            <div>
              {JSON.stringify(hit.fields.completename).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}
              <br/>
              { <Link onClick={() => {window.location.href="/formerministerdetails/"+ JSON.stringify(hit.fields.slug).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}} >view details</Link>}
            </div>
          )
        } else if (content_type === "directorates") {
          return (
            <div>
              {JSON.stringify(hit.fields.name).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}
              <br/>
              { <Link onClick={() => {window.location.href="/directoratedetails/"+ JSON.stringify(hit.fields.slug).replace(/[|&;$%@"{}<>()+,:]/g, "").substring(5)}} >view details</Link>}
            </div>
          )
        } else {
          return (
            <div>catch all</div>
          )
        }
      })()}
      </div>
    )
    
}

const SearchList = () => (
<MuiThemeProvider>
  <Card>
    <CardContent>
      <InstantSearch
        indexName="prod_mofwebsite"
        searchClient={searchClient}
      >
      <br/>
        <SearchBox />
        <Divider/>
        <RefinementList attribute="brand" />
      <br/>
        <Hits hitComponent={TitleContent}/>
        <br/>
        <Pagination />
        <br/>
      </InstantSearch>
    </CardContent>
  </Card>
  </MuiThemeProvider>
);

export default SearchList;
