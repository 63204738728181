import React from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { SimpleShareButtons } from "react-simple-share";
import {Link} from 'react-router-dom'
import "./styles.css";
import { Chip } from "@material-ui/core";
import DateRangeIcon from '@material-ui/icons/DateRange';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';

const SocialShare = () => (
    <SimpleShareButtons
      whitelist={["Facebook", "Twitter", "LinkedIn", "Google+"]}
      size="20px"
    />
  );

const Currencyformatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  });

const muiBaseTheme = createMuiTheme();
const theme = {
  overrides: {
    MuiCard: {
      root: {
        "&.MuiEngagementCard--01": {
          transition: "0.3s",
          maxWidth: 300,
          margin: "auto",
          boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
          "&:hover": {
            boxShadow: "0 30px 70px -12.125px rgba(0,0,0,0.5)"
          },
          "& .MuiCardMedia-root": {
            paddingTop: "56.25%"
          },
          "& .MuiCardContent-root": {
            textAlign: "left",
            padding: muiBaseTheme.spacing.unit * 3
          },
          "& .MuiDivider-root": {
            margin: `${muiBaseTheme.spacing.unit * 3}px 0`
          },
          "& .MuiTypography--heading": {
            fontWeight: "bold"
          },
          "& .MuiTypography--subheading": {
            lineHeight: 1.8
          },
          "& .MuiAvatar-root": {
            display: "inline-block",
            border: "2px solid white",
            "&:not(:first-of-type)": {
              marginLeft: -muiBaseTheme.spacing.unit
            }
          }
        }
      }
    }
  }
};

export default function CustomDataInfo(props) {
  return (
      props.customrevenuecollections? (
        <MuiThemeProvider theme={createMuiTheme(theme)}>
        <div className="App">
        <Link onClick={() => {window.location.href="/customrevenuedetails/"+ props.customrevenuecollections.fields.slug}} style={{ textDecoration: 'none' }}>
          <Card className={"MuiEngagementCard--01"}>
            <CardMedia
              image={props.customrevenuecollections.fields.customlogo.fields.file.url}
            />
            <CardContent>
            Tax Period : 
                <Typography
                  className="text"
                  variant={"h7"}
                  gutterBottom
                >
                  
                  <Chip
                icon={<DateRangeIcon />}
                label={props.customrevenuecollections.fields.title}
                clickable
                color="success"
                    />
              </Typography>
              Total Tax Collected : 
              <Chip
                icon={<MonetizationOnIcon />}
                label={Currencyformatter.format(props.customrevenuecollections.fields.totaltaxcollected)}
                clickable
                color="primary"
                    />
                <Divider black />
              <SocialShare
                    url = {"https://mof.gov.tl/customrevenuedetails/" + props.customrevenuecollections.fields.slug }
                />
                <Button
                    variant="contained"
                    color="info"
                    startIcon={<VisibilityIcon />}
                  >
                    view details
                </Button>
            </CardContent>
            
          </Card>
          </Link>
        </div>
      </MuiThemeProvider>
      ) : "Please wait while data is being fetched" 
  )
}
