import React from 'react'
import { useEffect, useState} from 'react';
import Axios from 'axios';
import Dropdown from 'react-dropdown';
import { HiSwitchHorizontal } from 'react-icons/hi';
import 'react-dropdown/style.css';
import TextField from '@material-ui/core/TextField';
import "./Converter.css"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

function CurrencyConverter() {
const classes = useStyles();

// Initializing all the state variables
const [info, setInfo] = useState([]);
const [input, setInput] = useState(0);
const [from, setFrom] = useState("usd");
const [to, setTo] = useState("inr");
const [options, setOptions] = useState([]);
const [output, setOutput] = useState(0);

// Calling the api whenever the dependency changes
useEffect(() => {
	Axios.get(
`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${from}.json`)
.then((res) => {
	setInfo(res.data[from]);
	})
}, [from]);

// Calling the convert function whenever
// a user switches the currency
useEffect(() => {
	setOptions(Object.keys(info));
}, [info])
	
// Function to convert the currency
function convert() {
	var rate = info[to];
	setOutput(input * rate);
}

// Function to switch between two currency
function flip() {
	var temp = from;
	setFrom(to);
	setTo(temp);
}

return (
   <div className={classes.root}>
    <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center">
    
        <Grid item xs={3}>
        <h3>Amount</h3>
        <TextField
            variant="outlined"
            required
            label="Enter the amount"
            name="completename"
            autoComplete="completename"
            autoFocus
            onChange={(e) => setInput(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
        <h3>From</h3>
          <Dropdown options={options}
                onChange={(e) => { setFrom(e.value) }}
          value={from} placeholder="From" />
        </Grid>
        <Grid item xs={3}>
        <div className="middle center">
        <h3>Switch</h3>
        <HiSwitchHorizontal size="30px"
						onClick={() => { flip()}}/>
        </div>
        </Grid>
        <Grid item xs={3}>
        <h3>To</h3>
          <Dropdown options={options}
                onChange={(e) => {setTo(e.value)}}
          value={to} placeholder="To" />
        </Grid>
        
      </Grid>
      <div className="result">
        <button onClick={()=>{convert()}} className="button buttonConvert">Convert</button>
        <h2>Converted Amount:</h2>
        <p>{input+" "+from+" = "+output.toFixed(2) + " " + to}</p>
      </div>
    </div>
);
}

export default CurrencyConverter;
