import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Container from '@material-ui/core/Container';
import MoreIcon from '@material-ui/icons/MoreVert';
import { Button } from '@material-ui/core';
import LogoMoF from "../Logo/Logo"
import AboutMenu from "../Navbar/MainMenus/AboutMenu"
import ProcurementMenu from "../Navbar/MainMenus/ProcurementMenu"
import DpmuMenu from "../Navbar/MainMenus/DpmuMenu"
import BudgetMenu from "../Navbar/MainMenus/BudgetMenu"
import EconomyMenu from "../Navbar/MainMenus/EconomyMenu"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(-6),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function Navbar() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);



  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Konaba ami</MenuItem>
      <MenuItem onClick={handleMenuClose}>Kontaktu ami</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button color="inherit" href="/">
          Home
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit" href="/profile">
          <AboutMenu/>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit" href="/procurementpage">
          <ProcurementMenu/>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit" href="/budgetspendingpage">
          <BudgetMenu />
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit" href="/aidpage">
          AID Program
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit" href="/economypage">
          <EconomyMenu/>
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit" href="/pressrelease">
          Newds & Press Release
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit" href="/searchpage">
          <SearchIcon/>
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar  style={{ background: '#faf6fa' }} position="sticky">
        <Container>
            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="#530052"
                aria-label="open drawer"
                href="/"
              ></IconButton>
                <LogoMoF />
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                  <Button  href="/">
                    Home
                  </Button>
                  <Button  href="/profile">
                    <AboutMenu/>
                    <ArrowDropDownIcon/>
                  </Button>
                  <Button  href="/procurementpage">
                  <ProcurementMenu/>
                  <ArrowDropDownIcon/>
                  </Button>
                  <Button  href="/budgetspendingpage">
                    <BudgetMenu />
                    <ArrowDropDownIcon/>
                  </Button>
                  <Button  href="/aidpage" icon="<ArrowDropDownIcon/>">
                   <DpmuMenu/>
                   <ArrowDropDownIcon/>
                  </Button>
                  <Button  href="/economypage">
                  <EconomyMenu/>
                  <ArrowDropDownIcon/>
                  </Button>
                  <Button  href="/pressrelease">
                    News & Press Release
                  </Button>
                  <Button  href="/searchpage">
                  <SearchIcon/>
                  </Button>
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    href="/searchpage"
                    onClick={handleMobileMenuOpen}
                    color="black"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
              </Toolbar>
          </Container>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
