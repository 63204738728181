import React, { Component } from 'react'
import PropTypes from 'prop-types';
import client from '../Contentful_API/api';
import { withStyles } from '@material-ui/core/styles';
import { SimpleShareButtons } from "react-simple-share";
import styles from './Styles';
import Grid from '@material-ui/core/Grid';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./styles.css"

// // Import Bar Graphic
import { Bar } from "react-chartjs-2";
// import { Line } from 'react-chartjs-2';

// Import Line Graphic
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
  } from 'chart.js';
 
import "chartjs-plugin-datalabels";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(ChartDataLabels);
  
  ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
  );


class RevenueCollectionDetails extends Component {
	constructor(){


		 super()
		 this.state = {customrevenuecollections: null}
		
	}

	componentDidMount() {
   	 client.getEntries({content_type: 'customrevenuecollections', 'fields.slug': this.props.match.params.slug})
      .then(response => {
        return response.items[0].fields;
      })
      .then(fields => {
        this.setState({
            customrevenuecollections: fields
        });
      })
      .catch(console.error);
  }



	render(props){
		 const Currencyformatter = new Intl.NumberFormat('en-US', {
		 	style: 'currency',
		 	currency: 'USD',
		   });
		const {classes} = this.props;
    	let title;
		let salestax;
        let excisetax;
        let importcustomduty;
        let processingfee;
        let penalty;
        let laterate;
        let totaltaxcollected;
    	let slug;
		let customlogo;
		if (this.state.customrevenuecollections) {
      	title = this.state.customrevenuecollections.title;
      	slug = this.state.customrevenuecollections.slug;
		salestax = this.state.customrevenuecollections.salestax;
        excisetax = this.state.customrevenuecollections.excisetax;
        importcustomduty = this.state.customrevenuecollections.importcustomduty;
        processingfee = this.state.customrevenuecollections.processingfee;
        penalty = this.state.customrevenuecollections.penalty;
        laterate = this.state.customrevenuecollections.laterate;
        totaltaxcollected = this.state.customrevenuecollections.totaltaxcollected;
        customlogo = this.state.customrevenuecollections.customlogo.fields.file.url; 
		

		}
		const DatachartSet = [
			{
				id: 1,
				labelsChart: 'Sales Tax',
				userGain: salestax,
				
			},
			{
				id: 2,
				labelsChart: 'Excise Tax',
				userGain: excisetax,
				
			},
			{
				id: 3,
				labelsChart: 'Import Custom Duty',
				userGain: importcustomduty,
				
			},
			{
				id: 4,
				labelsChart: 'Processing Fee',
				userGain: processingfee,
				
			},
			{
				id: 5,
				labelsChart: 'Penalty',
				userGain: penalty,
				
			},
			{
				id: 6,
				labelsChart: 'Late',
				userGain: laterate,
				
			},
			{
				id: 7,
				labelsChart: 'Total Tax Collected',
				userGain: totaltaxcollected,
				
			},
		]
		const data = {
			  labels: DatachartSet.map(o => o.labelsChart),

			  datasets: [
				{
				  label: 'Custom Revenue Collections Report',
				  backgroundColor: [
				  "#6cf0f1",
				  "#3571c9",
				  "#7a71d9",
				  "#5b71a9",
				  "#DC3535",
				  "#f3ba2f",
				  "#50AF95"], 
				  borderColor: 'rgb(110, 203, 012)',
				  borderWidth: 1,
				  data: DatachartSet.map(o => o.userGain),


				}
			  ]
			};
			const options = {
			maintainAspectRatio: false,			
			scales: {
				x: {
				  stacked: true
				},
				y: {
				  stacked: true
				}
			  },
			  plugins: {
				// Titel 
				title: {
				  display: true,
				  text: 'Tax Period, From '+ title,
				  //text: 'Bar Chart, From '+ title,
				  font: {
					size: 16
				  }
				},
				datalabels: {
					display: true,
					color: "red",
					align: "end",
					anchor: 'end',
					padding: {
						top: 90,
						right: -15,
						bottom: -90,
						left: 0
					},
					rotation: function(context) {
					var value = context.dataset.data[context.dataIndex];
					return value > 0 ? -45 : 0 - 45;
					  },
					font: {
						weight: "bold",
						size: 12,
					  },
					formatter: function (value) {
					  return (Currencyformatter.format(value));
					}
				  }
				
			  }
			};


		 return(
			
			<div className={classes.layout}>
				<HelmetProvider>
					<Helmet defer={false}>
						<title>{title}</title>
						<meta property="og:title" content={title} />
						<meta property="og:image" content={customlogo} />
					</Helmet>
				</HelmetProvider>
			 <Grid container>
              {/* <Grid item md={8}>
				<div className={classes.imageLayout}>
					<img className={classes.img} alt="complex" src= {customlogo} />
				</div>
				<div className={classes.socialShare}>
					<h5>Share with</h5>
					<SimpleShareButtons
						url = {"https://www.mof.gov.tl/details/"+ slug }
						size="50px"
					/>
				</div>
			  </Grid>
			  <Grid item md={12}>

				  	<ul>
						<li> Title:  {title}</li>
						<li> Sales Tax : {Currencyformatter.format(salestax)}</li>
						<li>Excise Tax: {Currencyformatter.format(excisetax)}</li>
						<li>Import Custom Duty: {Currencyformatter.format(importcustomduty)}</li>
						<li>Processing Fee: {Currencyformatter.format(processingfee)}</li>
						<li>Penalty: {Currencyformatter.format(penalty)}</li>
						<li>Late : {Currencyformatter.format(laterate)}</li>
						<li>Total Tax Collected: {Currencyformatter.format(totaltaxcollected)}</li>
					</ul>
 
			  </Grid> */}

			  {/* Graphic ChartBar customrevenuecollections  */}
			  <article className="canvas-container">
			  <Bar options={options} data={data} />
              	{/* <Line options={options} data={data} /> */}
			  </article>
			</Grid>
			<h5>Share with</h5>
					<SimpleShareButtons
						url = {"https://www.mof.gov.tl/details/"+ slug }
						size="30px"
					/>

				{/* <div style={{ width: "900px", margin-left: "50px 50px 50px 50px" }}>
				 {/* Graphic Line Chart customrevenuecollections  */}
				{/* <Bar options={options} data={data} /> */}
				{/* </div> */}

			</div>

		);
	}
}

RevenueCollectionDetails.propTypes = {
	classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(RevenueCollectionDetails);
